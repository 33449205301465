.loader{

	position: fixed;
	z-index: 1200;

	width: 52px;
	height: 52px;

	
	//z-index: -1;
	animation: loader-rotate .73s linear infinite;

	@include breakpoint( 'small' ){
		left: calc( 50vw - 26px ) !important;
		top: calc( 50vh - 26px ) !important;
	}

	&[data-loading="false"]{
		//opacity: 0;
		//z-index: -1;
		//animation: none;

		span{
			opacity: 0;
		}
	}
	&[data-loading="true"]{
		//opacity: 1;
		
		
		span{
			opacity: 1;
		}
	}

	span{
		display: block;
		width: 5px;
		height: 5px;
		border-radius: 12px;
		opacity: 0;
		transition: all .5s ease-in-out;

		@include breakpoint( 'small' ){
			width: 5px;
			height: 5px;
			border-radius: 20px;
		}
	}
}

[data-page="home"] .loader span{
	background: $color_text;
}
[data-page="work"] .loader span,
[data-page="projects"] .loader span,
[data-page="blog"] .loader span{
	background: $color_brand;
}
[data-page="experience"] .loader span,
[data-page="contact"] .loader span{
	background: $color_background;
}

@keyframes loader-rotate{
	0% {transform: rotate(0) }
	50% {transform: rotate(180deg) }
	100% {transform: rotate(360deg) }
}