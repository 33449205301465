.blog-articles{
	position: relative;
	margin: 0 auto;
	width: 100%;
	max-width: 1300px;
	text-align: left;
}

.blog-list{
	
	text-align: left;
    float: right;
	max-width: 30%;
	margin: 80px 20px;

	@include breakpoint( 'small' ){
		display: none;
	}

	&__item{
		display: block;
		margin: 0 0 0 0;
		padding: 20px 0 10px 20px;
		border-left: 5px solid transparent;
		transition: all .2s ease-in-out;

		@include breakpoint( 'small' ){
			padding: 5px 15px;
		}

		&-text{
			font-size: 26px;
			line-height: 32px;
			font-weight: 700;
			color: $color-background;

			transition: all .2s ease-in-out;
		}

		&-date{
			display: block;
			margin-bottom: 0px;
			font-size: 10px;
			font-weight: 700;
			color: $color-brand;
		}
	
		$this: &;

		&.active{
			
			border-left-color: $color-brand;
		
			#{$this}-date{
				//opacity: .2;
			}
			#{$this}-text{
				//opacity: .2;
			}
		}

		&:hover:not(.active){
			border-left-color: $color-brand;
			#{$this}-text{
				//color: $color-text;
			}
		}

		
	}
}

.blog-article{

	float: left;
	display: inline-block;
	position: relative;
	padding: 10px 25px;
	width: 60%;
	min-height: 50vh;
	margin: 80px 20px;
	//display: flex;
	//align-items: center;
	transition: all .25s ease-out;
	color: #222;

	@include breakpoint( 'small' ){
		float: none;
		width: auto;
		max-width: 100%;
		margin: 20px 0px 60px 0;
	}

	$this: &;

	&__title{
		display: block;
		margin: -14px 0 20px -4px;
		padding: 0;
		font-size: 50px;
		font-weight: 700;
		line-height: 58px;
		letter-spacing: 0;
		//font-family: 'Bitter', sans-serif;
		transition: all .2s ease-in-out;

		@include breakpoint( 'small' ){
			margin: 10px 0 10px -4px;
			font-size: 40px;
			line-height: 48px;
		}
	}

	&__media{
		figure{

			margin: 30px 0;

			img{
				width: 100%;
				display: block;
			}
		}
	}

	&__date{
		display: block;
		margin: 0 20px 25px 0;
		padding: 0;
		font-size: 12px;
		line-height: 14px;
		font-weight: 700;
		color: $color-brand;
		font-family: 'Bitter', sans-serif;
	}

	&__desc{
		display: inline-block;
		margin: 0 0 25px 0;
		padding: 0;
		font-size: 16px;
		line-height: 26px;
		font-weight: 400;
		color: invert( $color-text );
		font-family: 'Bitter', sans-serif;
	}

	&__text{
		display: block;
		margin: 0 0 0 0;
		padding: 0;
		font-size: 16px;
		line-height: 26px;
		font-weight: 400;
		color: #222;
		//font-family: 'Bitter', sans-serif;

		@include breakpoint( 'small' ){
			margin: 10px 0 0 0;
		}

		h2{
			font-size: 35px;
			line-height: 43px;
		}	
		h3{
			font-size: 30px;
			line-height: 38px;
		}
		h4{
			font-size: 25px;
			line-height: 32px;
		}

		a{
			color: invert( $color-text );
			font-weight: 700;
			text-decoration: none;
			border-bottom: 2px solid invert( $color-text );
			transition: all .2s ease-in-out;
		}

		figure{
			margin: 0;
			width: 100%;

			img{
				max-width: 100%;
			}
		}

		pre{
			font-size: 12px;
			line-height: 20px;
			white-space:pre-wrap; 
			word-wrap:break-word;
		}

		table{
			display: block;
			overflow-x: auto;
			white-space: nowrap;
			border: none;
			tbody{
				min-width: 100vw;
			}
			tr{
				&:first-of-type{
					td{
						background: $color-brand;
						color: $color-text;
					}
				}
			}
			tr{
				td{
					padding: 5px 15px;
					font-size: 13px;
					background: $color-text;
				}
			}
		}
	}

	&__social{
		margin-top: 50px;
		display: none;

		a{
			margin: 0;

			& > div{
				border-radius: 0;
				background: transparent;
				transition: all .2s ease-in-out;
			}

			& svg{
				path{
					fill: #222;
				}
			}

			&:hover{
				& > div{
					background: #222;
				}
				svg{
					path{
						fill: #fff;
					}
				}
			}
			
		}
	}
}

.blog-pages{
	
	text-align: left;
	margin: 20px 20px;
	display: none;

	@include breakpoint( 'small' ){
		display: block;
	}

	&__title{
		display: block;
		margin: -14px 0 20px -4px;
		padding: 0;
		font-size: 40px;
		font-weight: 700;
		line-height: 58px;
		letter-spacing: 0;
		//font-family: 'Bitter', sans-serif;
		transition: all .2s ease-in-out;
	}

	&__item{
		display: block;
		margin: 8px 0;
		padding: 5px 0 5px 20px;
		transition: all .2s ease-in-out;
		vertical-align: bottom;


		&-text{
			font-size: 16px;
			line-height: 22px;
			font-weight: 700;
			color: $color-background;

			transition: all .2s ease-in-out;
		}

		&-date{
			margin-right: 5px;
			font-size: 10px;
			font-weight: 700;
			line-height: 22px;
			color: $color-brand;
		}
	
		$this: &;

		&.active{
			padding-left: 15px;
			border-left: 5px solid $color-brand;

			#{$this}-date{
				//opacity: .2;
				padding-left: 10px;
			}
			#{$this}-text{
				//opacity: .2;
			}
		}

		&:hover:not(.active){
			#{$this}-text{
				color: $color-brand;
			}
		}

		
	}
}