.menu-open{
	position: fixed;
    top: 25px;
    right: 25px;
	z-index: 210;

	
	&:hover{
		color: $color_brand;
	}

	@include breakpoint( 'small' ){
		display: block;
	}
}

body[data-page="home"]{
	.menu-open{
		//background: $color_background;
	}
}

body.detail-open{
	.menu-open{
		display: none;
	}
}

.menu{
	position: fixed;
	z-index: 210;
	top: 0;
	left: auto;
	right: -105vw;
	
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	transition: all .7s cubic-bezier(0.68, -0.6, 0.32, 1);

	background: rgba( $color_background, .8);
	background: -webkit-linear-gradient(315deg, darken(rgba($color_background,.99),8%), lighten(rgba($color_background,.99),2%));
	background: linear-gradient(315deg, darken(rgba($color_background,.99),8%), lighten(rgba($color_background,.99),2%));

	&.active{
		right: -50vw;
		@include breakpoint('small'){
			right: -15vw;
		}
	}

	&--hidden{
		max-height: 0;
	}

	ul{
		position: absolute;
		list-style: none;
		right: 50vw;
		bottom: 25vh;
		width: 100vw;

		@include breakpoint( 'small' ){
			right: 15vw;
			bottom: 30vh;
		}

		li{
			position: relative;
			overflow: hidden;
			display: block;
			margin: 0 25px;
			padding: 0;
			text-align: right;
			transition: all .8s ease-in-out;

			a{
				display: inline-block;
				border-bottom: 3px solid transparent;
				transition: all .3s ease-in-out;
				text-decoration: none;
		
				font-size: 50px;
				line-height: 65px;
				font-weight: 700;
		
				color: $color_text;
		
				@include breakpoint( 'small' ){
					//font-size: 22px;
					//line-height: 30px;
				}
		
				&:not(.active):hover{
					padding-right: 20px;
				}
				&.active{
					color: $color_brand;
				}

				opacity: 0;
				transform: translateX(50%);
			}

			&.done{
				a{
					opacity: 1;
					transform: translateX(0);
					transition: all .2s ease-in-out;
				}
			}
		}
	}

	

	&-close{
		position: absolute;
		z-index: 401;
		margin: 0 25px;
		top: 25px;
		right: 50vw;
		color: $color_text;
		border-color: $color_text;
		display: block;

		@include breakpoint('small'){
			right: 15vw;
		}

		&:hover{
			color: $color_brand;
			border-color: $color_brand;
		}

	}

	&-contact{
		position: absolute;
		bottom: 75px;
		right: 50vw;
		margin: 0 25px;

		@include breakpoint('small'){
			right: 15vw;
			bottom: 150px;
		}

		a{
			margin: 0 0 0 25px;
			color: $color_text;
			font-size: 50px;
			line-height: 58px;
			font-weight: 700;

			&:hover{
				color: $color_brand;
			}

			@include breakpoint( 'small' ){
				font-size: 22px;
				line-height: 30px;
			}
		}
	}

	&-info{
		position: absolute;
		bottom: 25px;
		right: 50vw;
		margin: 0 25px;

		color: $color_text;
		font-size: 12px;
		font-weight: 700;
		letter-spacing: 0;

		@include breakpoint('small'){
			right: 15vw;
			bottom: 75px;
		}

		a{
			color: $color_brand;
			font-weight: 700;
		}
	}
}



/*** COLOR STATES ***/

[data-page="home"]{
	.menu-open{
		color: $color_text;
		border-color: $color_text;
		
		&:hover{
			color: $color_brand;
			border-color: $color_brand;
		}
	}
}

[data-page="experience"]{
	.menu-open{
		color: $color_text;
		border-color: $color_text;

		&:hover{
			color: $color_background;
			border-color: $color_background;
		}
	}
}

[data-page="contact"]{
	.menu-open{
		color: $color_text;
		border-color: $color_text;
	}
}