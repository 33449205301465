@mixin breakpoint($breakpoint) {
	// If the key exists in the map
	@if map-has-key($breakpoints, $breakpoint) {
	  // Prints a media query based on the value
	  @media (max-width: map-get($breakpoints, $breakpoint)) {
		@content;
	  }
	}
   
	// If the key doesn't exist in the map
	@else {
	  @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
		  + "Available breakpoints are: #{map-keys($breakpoints)}.";
	}
  }