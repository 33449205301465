html {
    //overflow: scroll;
    //overflow-x: hidden;
}
::-webkit-scrollbar {
	width: 0px;
	background: transparent;
}
::-webkit-scrollbar-thumb {
    background: $color-brand;
}

body {
	width: 100vw;
	height: 100vh;
	
	margin: 0;
	padding: 0;

	overflow-x: hidden;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-family: 'PT Sans', sans-serif;
}

* {
	box-sizing: border-box;
}

html,body,*{
	cursor: none !important;
}

#main{
	padding: 0;
	margin: 0;
}


.switch-wrapper {
	position: relative;
  
 	& > div {
		width: 100%;
		position: absolute;
	}
}

.clearfix{
	&:after{
		clear: both;
		display: table;
		content: ' ';
	}
}


.left {
	float: left;
}
.right {
	float: right;
}

.column{
	float: left;

	@include breakpoint( 'medium' ){
		float: none;
		width: 100% !important;
	}

	&.col-2{
		width: 50%;
	}
	&.col-3{
		width: 33.3%;
	}
	&.col-4{
		width: 25%;
	}
}

.hide{
	display: none !important;
}

pre{
	padding: 10px;
	display: block;
	background: #f7f7f7;
}

.btn{
	color: invert( $color-text );
	text-decoration: none;
	padding: 10px;
	font-weight: 700;
	outline: none;
	transition: all .25s ease-out;
	border: 5px solid invert( $color-text );

	&:hover{
		color: $color-brand;
		border-color: $color-brand;
	}
}