.fx{

	&.fx-slide{
		opacity: 0;
		transform: translateX(-25px);
		transition: all .5s cubic-bezier(0,.28,.78,1);
	}

	&.fx-fade{
		opacity: 0;
		transition: all .2s cubic-bezier(0,.28,.78,1);
	}

	&.fx-char{
		opacity: 0;
		transform: rotate(10deg) translateY(20px);
		transition: all .2s cubic-bezier(0,.28,.78,1);
	}

	// done
	&.fx-done{
		opacity: 1;
		transform: translate(0,0) rotate(0);
	}

}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes flip {
	0% {
		transform: rotate(0);
	}
	33% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}


@keyframes wobble {
	0% {
		transform: rotate(0);
	}
	33% {
		transform: rotate(.5deg);
	}
	66% {
		transform: rotate(-.5deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
@keyframes zoom {
	0% {
		transform: scale(1,1);
	}
	100% {
		transform: scale(1.02,1.02);
	}
}	

@keyframes border-dance {
	0% {
	  background-position: 0px 0px, 300px 116px, 0px 150px, 216px 0px;
	}
	100% {
	  background-position: 300px 0px, 0px 116px, 0px 0px, 216px 150px;
	}
  }