body.detail-open{
	height: 100vh;
	overflow: hidden;
}
.detail{
	position: fixed;
	bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
	color: $color_text;
	overflow: auto;
	opacity: 1;
	z-index: 999;

	transition: all .73s  cubic-bezier(0,.78,.28,1);
	transform: translateY(0vh);

	&.mount{
		height: 100vh;
		transform: translateY(0) ;
	}


	.wrapper{
		max-width: 1300px;
		margin: 0 auto;
		padding: 100px;

		@include breakpoint( 'small' ){
			padding: 80px 30px 30px 30px;
		}
	}

	&__title{
		font-size: 100px;
		font-weight: 700;
		line-height: 80px;
		margin: 0 0 80px 0;
		min-height: 80px;

		@include breakpoint( 'small' ){
			font-size: 30px;
			line-height: 40px;
			margin: 0 0 40px 0;
			min-height: 40px;
		}

		span{
			display: inline-block;

			&.space{
				&:after{
					opacity: 0;
					content: '.';
				}
			}
		}
	}
	
	&__desc{
		display: block;
		overflow: hidden;
		margin: 0 0 80px 0;
		font-size: 30px;
		line-height: 40px;
		font-weight: 300;
		
		transition: height .1s ease-in;

		@include breakpoint( 'small' ){
			font-size: 20px;
			line-height: 28px;
			margin: 0 0 40px 0;
		}
	}

	&__desc a{
		text-decoration: none;
		padding: 10px 20px;
		font-size: 14px;
		font-weight: 700;
		outline: none;
		transition: all .25s ease-out;
		color: $color-text;
		border: 5px solid $color-text;

		&:hover{
			color: $color-brand;
			border-radius: 0 15px 0 0;
			border-color: $color-brand;
		}
	}

	&__media{

		opacity: 0;
		transition: all .5s ease-out;

		&.active{
			opacity: 1;
		}

		.figure{
			margin: 0 0 50px 0;
			background: transparent;
			&__overlay{
				background: $color_text;
			}
		}

	}

	img,video{
		width: 100%;
		margin: 0;
	}
}

.detail-underlay{
	position: fixed;
	z-index: 998;

	right: 0;
	bottom: 0;

	width: 0vw;
	height: 100vh;
	transform: rotate(3deg) scale(2,2) translateX(20px);

	//background: $color_background;
	
	background: linear-gradient(-25deg, lighten($color_background,25%), $color_background);
	background-size: 400% 400%;

	transition: all 2.23s cubic-bezier(.75,1,0,.98) !important;

	overflow: hidden;

	&.mount{
		width: 100vw;
		transform: rotate(-3deg) scale(2,2)  translateX(20px);
	}
}

.detail-close{
	position: fixed;
	top: 20px;
	right: -100px;
	z-index: 1001;
	transition: all .2s ease-in .1s;
	color: $color_text;
	border-color: $color_text;

	&.mount{
		right: 20px;
		transition: all .2s ease-out;

		@include breakpoint( 'small' ){
			right: 20px;
		}
	}
}