@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700|Bitter:400,700,900);
.fx.fx-slide {
  opacity: 0;
  -webkit-transform: translateX(-25px);
          transform: translateX(-25px);
  transition: all 0.5s cubic-bezier(0, 0.28, 0.78, 1); }

.fx.fx-fade {
  opacity: 0;
  transition: all 0.2s cubic-bezier(0, 0.28, 0.78, 1); }

.fx.fx-char {
  opacity: 0;
  -webkit-transform: rotate(10deg) translateY(20px);
          transform: rotate(10deg) translateY(20px);
  transition: all 0.2s cubic-bezier(0, 0.28, 0.78, 1); }

.fx.fx-done {
  opacity: 1;
  -webkit-transform: translate(0, 0) rotate(0);
          transform: translate(0, 0) rotate(0); }

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@keyframes gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@-webkit-keyframes flip {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  33% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes flip {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  33% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  33% {
    -webkit-transform: rotate(0.5deg);
            transform: rotate(0.5deg); }
  66% {
    -webkit-transform: rotate(-0.5deg);
            transform: rotate(-0.5deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes wobble {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  33% {
    -webkit-transform: rotate(0.5deg);
            transform: rotate(0.5deg); }
  66% {
    -webkit-transform: rotate(-0.5deg);
            transform: rotate(-0.5deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@-webkit-keyframes zoom {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  100% {
    -webkit-transform: scale(1.02, 1.02);
            transform: scale(1.02, 1.02); } }

@keyframes zoom {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  100% {
    -webkit-transform: scale(1.02, 1.02);
            transform: scale(1.02, 1.02); } }

@-webkit-keyframes border-dance {
  0% {
    background-position: 0px 0px, 300px 116px, 0px 150px, 216px 0px; }
  100% {
    background-position: 300px 0px, 0px 116px, 0px 0px, 216px 150px; } }

@keyframes border-dance {
  0% {
    background-position: 0px 0px, 300px 116px, 0px 150px, 216px 0px; }
  100% {
    background-position: 300px 0px, 0px 116px, 0px 0px, 216px 150px; } }

::-webkit-scrollbar {
  width: 0px;
  background: transparent; }

::-webkit-scrollbar-thumb {
  background: #449595; }

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'PT Sans', sans-serif; }

* {
  box-sizing: border-box; }

html, body, * {
  cursor: none !important; }

#main {
  padding: 0;
  margin: 0; }

.switch-wrapper {
  position: relative; }
  .switch-wrapper > div {
    width: 100%;
    position: absolute; }

.clearfix:after {
  clear: both;
  display: table;
  content: ' '; }

.left {
  float: left; }

.right {
  float: right; }

.column {
  float: left; }
  @media (max-width: 1024px) {
    .column {
      float: none;
      width: 100% !important; } }
  .column.col-2 {
    width: 50%; }
  .column.col-3 {
    width: 33.3%; }
  .column.col-4 {
    width: 25%; }

.hide {
  display: none !important; }

pre {
  padding: 10px;
  display: block;
  background: #f7f7f7; }

.btn {
  color: #080808;
  text-decoration: none;
  padding: 10px;
  font-weight: 700;
  outline: none;
  transition: all .25s ease-out;
  border: 5px solid #080808; }
  .btn:hover {
    color: #449595;
    border-color: #449595; }

.menu-open {
  position: fixed;
  top: 25px;
  right: 25px;
  z-index: 210; }
  .menu-open:hover {
    color: #449595; }
  @media (max-width: 767px) {
    .menu-open {
      display: block; } }

body.detail-open .menu-open {
  display: none; }

.menu {
  position: fixed;
  z-index: 210;
  top: 0;
  left: auto;
  right: -105vw;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition: all 0.7s cubic-bezier(0.68, -0.6, 0.32, 1);
  background: rgba(13, 13, 13, 0.8);
  background: linear-gradient(315deg, rgba(0, 0, 0, 0.99), rgba(18, 18, 18, 0.99)); }
  .menu.active {
    right: -50vw; }
    @media (max-width: 767px) {
      .menu.active {
        right: -15vw; } }
  .menu--hidden {
    max-height: 0; }
  .menu ul {
    position: absolute;
    list-style: none;
    right: 50vw;
    bottom: 25vh;
    width: 100vw; }
    @media (max-width: 767px) {
      .menu ul {
        right: 15vw;
        bottom: 30vh; } }
    .menu ul li {
      position: relative;
      overflow: hidden;
      display: block;
      margin: 0 25px;
      padding: 0;
      text-align: right;
      transition: all .8s ease-in-out; }
      .menu ul li a {
        display: inline-block;
        border-bottom: 3px solid transparent;
        transition: all .3s ease-in-out;
        text-decoration: none;
        font-size: 50px;
        line-height: 65px;
        font-weight: 700;
        color: #f7f7f7;
        opacity: 0;
        -webkit-transform: translateX(50%);
                transform: translateX(50%); }
        .menu ul li a:not(.active):hover {
          padding-right: 20px; }
        .menu ul li a.active {
          color: #449595; }
      .menu ul li.done a {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        transition: all .2s ease-in-out; }
  .menu-close {
    position: absolute;
    z-index: 401;
    margin: 0 25px;
    top: 25px;
    right: 50vw;
    color: #f7f7f7;
    border-color: #f7f7f7;
    display: block; }
    @media (max-width: 767px) {
      .menu-close {
        right: 15vw; } }
    .menu-close:hover {
      color: #449595;
      border-color: #449595; }
  .menu-contact {
    position: absolute;
    bottom: 75px;
    right: 50vw;
    margin: 0 25px; }
    @media (max-width: 767px) {
      .menu-contact {
        right: 15vw;
        bottom: 150px; } }
    .menu-contact a {
      margin: 0 0 0 25px;
      color: #f7f7f7;
      font-size: 50px;
      line-height: 58px;
      font-weight: 700; }
      .menu-contact a:hover {
        color: #449595; }
      @media (max-width: 767px) {
        .menu-contact a {
          font-size: 22px;
          line-height: 30px; } }
  .menu-info {
    position: absolute;
    bottom: 25px;
    right: 50vw;
    margin: 0 25px;
    color: #f7f7f7;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0; }
    @media (max-width: 767px) {
      .menu-info {
        right: 15vw;
        bottom: 75px; } }
    .menu-info a {
      color: #449595;
      font-weight: 700; }

/*** COLOR STATES ***/
[data-page="home"] .menu-open {
  color: #f7f7f7;
  border-color: #f7f7f7; }
  [data-page="home"] .menu-open:hover {
    color: #449595;
    border-color: #449595; }

[data-page="experience"] .menu-open {
  color: #f7f7f7;
  border-color: #f7f7f7; }
  [data-page="experience"] .menu-open:hover {
    color: #0D0D0D;
    border-color: #0D0D0D; }

[data-page="contact"] .menu-open {
  color: #f7f7f7;
  border-color: #f7f7f7; }

body {
  font-family: 'Source Sans Pro', sans-serif; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 100; }

p, a {
  margin: 0;
  padding: 0; }

a {
  text-decoration: none;
  font-weight: 400;
  transition: all .2s ease-in-out; }
  a:hover {
    text-decoration: none; }

button {
  padding: 0;
  line-height: 1.3;
  border: none;
  background: none; }

.home,
.work,
.projects,
.experience,
.blog,
.contact {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  text-align: center; }
  .home > .wrapper,
  .work > .wrapper,
  .projects > .wrapper,
  .experience > .wrapper,
  .blog > .wrapper,
  .contact > .wrapper {
    width: 100%;
    margin: 130px 0 0 0;
    display: block;
    overflow: hidden; }

.home {
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
  background-color: #0D0D0D;
  position: relative;
  width: 100vw;
  height: 100vh;
  /*
	background-image: url('/assets/img/bg.jpg');
	background-position: center 25%;
	background-size: cover;
	*/
  transition: all 1s ease-in-out; }
  .home.transit .home__overlay {
    background: rgba(10, 10, 10, 0.98); }
  .home.transit .home__video {
    opacity: 1; }
  .home.transit .home__content {
    opacity: 0; }
  .home.transit .home__transit {
    -webkit-transform: scale(0, 1);
            transform: scale(0, 1); }
  .home.transit .home__prefix {
    opacity: 0; }
  .home.transit .home__suffix {
    opacity: 0; }
  .home.transit .home__subcontent {
    opacity: 0; }
  .home__overlay {
    position: absolute;
    z-index: 0;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.4);
    transition: all .5s ease-in-out; }
  .home__duration {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 0;
    background: #fabbbb;
    transition: width .5s ease-out; }
    @media (max-width: 767px) {
      .home__duration {
        height: 5px; } }
  .home__video {
    position: absolute;
    z-index: 0;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 1;
    overflow: hidden;
    object-fit: cover;
    object-position: 50% 50%;
    transition: opacity .5s ease-in; }
  .home__image {
    position: absolute;
    z-index: 0;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 1;
    overflow: hidden;
    object-fit: cover;
    background-size: cover;
    background-position: center center;
    transition: all 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    -webkit-animation: 5s zoom infinite;
            animation: 5s zoom infinite; }
  .home__transit {
    position: absolute;
    z-index: 0;
    width: 50vw;
    height: 20vh;
    top: 40vh;
    left: 25vw;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.8);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    transition: all 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    box-shadow: 0 0 50px black; }
  .home__mute {
    position: fixed;
    z-index: 2;
    min-width: 100px;
    bottom: 25px;
    left: 25px;
    color: #fff;
    border-color: #fff; }
    .home__mute:hover span:after {
      border-bottom-color: #449595; }
    .home__mute span {
      position: relative;
      width: 100%;
      display: block;
      text-align: center;
      pointer-events: none; }
      .home__mute span:after {
        display: block;
        position: absolute;
        width: 0%;
        content: "";
        border-bottom: 2px solid #f7f7f7;
        left: 50%;
        margin-left: 0px;
        right: 0;
        top: calc( 50%);
        transition: all .5s ease-in-out; }
    .home__mute.muted span:after {
      margin-left: -20px;
      width: 40px; }
  .home__mute-status {
    position: fixed;
    z-index: 2;
    min-width: 50px;
    bottom: 55px;
    left: 25px;
    color: #fff;
    border-color: #fff; }
    .home__mute-status:not(.muted) .item--0,
    .home__mute-status:not(.muted) .item--1,
    .home__mute-status:not(.muted) .item--2 {
      display: block;
      position: absolute;
      content: "";
      width: 125px;
      height: 4px;
      z-index: 2000;
      left: 0;
      padding: 0;
      -webkit-animation: border-dance 15s infinite linear;
              animation: border-dance 15s infinite linear;
      background: linear-gradient(90deg, white 50%, transparent 50%), linear-gradient(90deg, white 50%, transparent 50%), linear-gradient(0deg, white 50%, transparent 50%), linear-gradient(0deg, white 50%, transparent 50%);
      background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
      background-size: 20px 5px, 20px 5px, 5px 20px, 5px 20px;
      background-position: 0px 0px, 200px 100px, 0px 100px, 200px 0px;
      opacity: 1; }
    .home__mute-status:not(.muted) .item--2 {
      left: 30px;
      top: -45px;
      width: 40px;
      display: none; }
    .home__mute-status:not(.muted) .item--1 {
      left: 20px;
      top: -35px;
      width: 60px;
      display: none; }
    .home__mute-status:not(.muted) .item--0 {
      left: 10px;
      top: -35px;
      width: 80px; }
    .home__mute-status:not(.muted) .item--disabled {
      -webkit-animation: none;
              animation: none; }
  .home .wrapper {
    position: relative;
    max-width: 1200px;
    margin: 50px auto;
    text-align: center; }
    @media (max-width: 767px) {
      .home .wrapper {
        overflow: visible; } }
    .home .wrapper .home__content {
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: flex-start;
              align-items: flex-start;
      margin-top: 0;
      font-size: 94px;
      line-height: 155px;
      font-weight: 700;
      letter-spacing: -3px;
      transition: all .5s ease-in-out; }
      @media (max-width: 1200px) {
        .home .wrapper .home__content {
          display: inline-block;
          -webkit-align-items: initial;
                  align-items: initial;
          line-height: 115px; } }
      @media (max-width: 767px) {
        .home .wrapper .home__content {
          padding: 0 25px;
          font-size: 70px;
          line-height: 80px; } }
    .home .wrapper .home__prefix {
      position: relative;
      font-weight: 700;
      color: #f7f7f7;
      transition: all .15s ease-out; }
    .home .wrapper .home__suffix {
      font-weight: 700;
      color: #f7f7f7;
      transition: all .35s ease-in; }
    .home .wrapper .home__letter {
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: baseline;
              align-items: baseline;
      max-width: 0;
      overflow: hidden;
      -webkit-transform: translateX(-10px) translateY(10px) rotate(30deg);
              transform: translateX(-10px) translateY(10px) rotate(30deg);
      transition: all .3s ease-out 2s;
      letter-spacing: -1.5px;
      color: #f7f7f7; }
      @media (max-width: 1024px) {
        .home .wrapper .home__letter--break {
          display: block;
          line-height: 0; } }
      .home .wrapper .home__letter.active {
        opacity: 1;
        max-width: 200px;
        -webkit-transform: translateX(0px);
                transform: translateX(0px); }
  .home .home__subcontent {
    position: absolute;
    display: inline-block;
    top: 0;
    right: 0;
    transition: all 2s ease-out;
    margin: 0 4px 0 0;
    min-width: 300px;
    text-align: right;
    font-size: 25px;
    line-height: 25px;
    font-weight: 700;
    letter-spacing: -1px;
    color: #f7f7f7; }
    @media (max-width: 767px) {
      .home .home__subcontent {
        top: -25px;
        right: 0px;
        width: 100%; } }

.experience {
  margin: 0 auto;
  background-color: #449595;
  transition: all 1s ease-in-out; }
  .experience .items {
    max-width: 1300px;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    counter-reset: cntr; }
  .experience .item {
    position: relative;
    margin: 40px 40px 0 20px;
    text-align: left; }
    @media (max-width: 767px) {
      .experience .item {
        margin: 20px 10px; } }
    .experience .item:before {
      opacity: 0.2; }
    .experience .item__title {
      color: #f7f7f7; }
    .experience .item__subtitle {
      color: #0D0D0D; }
    .experience .item__date {
      color: #0D0D0D; }
    .experience .item:before {
      position: absolute;
      top: 0%;
      left: 26px;
      counter-increment: cntr +1;
      content: counter(cntr) ".";
      font-size: 10px;
      font-weight: 700;
      color: #0D0D0D; }

.work {
  background-color: #f7f7f7;
  transition: all 1s ease-in-out; }
  .work .item {
    display: block; }
  .work .react-images__navigation button {
    background: #449595 !important; }

.projects {
  background-color: #f7f7f7;
  transition: all 1s ease-in-out; }
  .projects .react-images__navigation button {
    background: #449595 !important; }

.blog {
  background-color: white; }
  .blog .article__text {
    margin: 10px 0 0 0;
    font-size: 12px;
    line-height: 19px; }
  .blog .react-images__navigation button {
    background: #449595 !important; }

.contact {
  position: relative;
  -webkit-align-items: center;
          align-items: center;
  /*background-image: url('/assets/img/bg/home.jpgXXX');*/
  background-position: bottom center;
  background-size: cover;
  background-color: #449595;
  transition: all 1s ease-in-out; }
  .contact .wrapper {
    position: relative;
    margin: 50px; }
    @media (max-width: 767px) {
      .contact .wrapper {
        margin: 50px 20px; } }
    .contact .wrapper .contact__content {
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: flex-end;
              align-items: flex-end;
      margin-top: 0;
      font-size: 94px;
      line-height: 110px;
      font-weight: 700;
      color: #f7f7f7;
      letter-spacing: -3px; }
      @media (max-width: 1024px) {
        .contact .wrapper .contact__content {
          display: inline-block;
          -webkit-align-items: initial;
                  align-items: initial; } }
      @media (max-width: 767px) {
        .contact .wrapper .contact__content {
          font-size: 70px;
          line-height: 80px; } }
    .contact .wrapper .contact__prefix {
      color: #0D0D0D;
      font-weight: 700;
      letter-spacing: -7px; }
    .contact .wrapper .contact__letter {
      display: inline-block;
      vertical-align: sub;
      transition: all .25s ease-out;
      opacity: 0;
      max-width: 0;
      overflow: hidden;
      -webkit-transform: translateX(-30px) translateY(30px) rotate(30deg);
              transform: translateX(-30px) translateY(30px) rotate(30deg); }
      @media (max-width: 1024px) {
        .contact .wrapper .contact__letter--break {
          display: block;
          line-height: 0; } }
      .contact .wrapper .contact__letter.active {
        opacity: 1;
        max-width: 200px;
        -webkit-transform: translateX(0px);
                transform: translateX(0px); }
  .contact .contact__links {
    margin: 50px 0 0 0;
    color: #f7f7f7; }
    @media (max-width: 767px) {
      .contact .contact__links {
        margin: 25px 0 0 0; } }
    .contact .contact__links a {
      display: inline-block;
      margin: 20px 20px;
      font-size: 12px;
      line-height: 30px;
      font-weight: 700;
      text-decoration: none;
      color: #f7f7f7;
      transition: all .2s ease-in-out; }
      .contact .contact__links a:hover {
        color: #fff; }

.error {
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .error .wrapper {
    margin: 0 auto;
    width: 600px;
    text-align: center; }
    .error .wrapper p {
      margin-top: 0;
      padding: 0 10px;
      font-size: 13px;
      line-height: 21px;
      color: #444; }

/*** animatos */
[data-page="home"] {
  background: #0D0D0D; }

[data-page="work"],
[data-page="projects"] {
  background: #f7f7f7; }

[data-page="experience"],
[data-page="contact"] {
  background: #449595; }

[data-animation="false"] .work,
[data-animation="false"] .experience,
[data-animation="false"] .projects {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1; }

[data-animation="true"] .work,
[data-animation="true"] .projects,
[data-animation="true"] .experience {
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
  opacity: 0; }

.items {
  position: relative;
  margin: 0;
  width: 100%;
  text-align: left; }

.item {
  display: inline-block;
  position: relative;
  margin: 40px 20px;
  padding: 10px 25px;
  overflow: hidden;
  color: #f7f7f7;
  transition: all 0.93s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -webkit-transform-origin: right bottom;
          transform-origin: right bottom;
  -webkit-transform: skewX(25deg) translateX(20px) scale(1.1, 0.5);
          transform: skewX(25deg) translateX(20px) scale(1.1, 0.5);
  max-height: 0;
  opacity: 0; }
  @media (max-width: 767px) {
    .item {
      display: block; } }
  .item.active {
    -webkit-transform: skewx(0deg) translatex(0px) scale(1, 1);
            transform: skewx(0deg) translatex(0px) scale(1, 1);
    max-height: 200px;
    opacity: 1; }
  .item[data-detail="true"]:hover .item__title {
    color: #449595; }
  .item .wrapper {
    width: 100%; }
  .item:before {
    color: #f7f7f7; }
  .item__title {
    display: inline-block;
    margin: 0 0 0 0px;
    padding: 0 0 10px 0;
    font-size: 50px;
    line-height: 58px;
    font-weight: 700;
    letter-spacing: 0;
    transition: all .2s ease-in-out; }
    @media (max-width: 767px) {
      .item__title {
        margin: 10px 0 10px 0;
        font-size: 40px;
        line-height: 48px; } }
  .item__image {
    display: none;
    max-width: 500px; }
  .item__date {
    float: left;
    display: block;
    margin: 0 5px 0 0;
    padding: 0;
    font-size: 9px;
    line-height: 10px;
    font-weight: 700;
    color: #449595;
    font-family: 'Bitter', sans-serif; }
  .item__subtitle {
    float: left;
    display: block;
    margin-top: 0;
    padding: 0;
    font-size: 9px;
    line-height: 10px;
    font-weight: 700;
    color: #f7f7f7;
    font-family: 'Bitter', sans-serif; }
  .item__description {
    display: block;
    max-width: 80%;
    margin-top: 15px;
    padding: 0;
    font-size: 13px;
    line-height: 20px;
    color: rgba(247, 247, 247, 0.9); }
    .item__description a {
      color: inherit;
      font-weight: 700;
      text-decoration: underline; }
  .item__cta {
    margin: 0 0 0 25px; }

.articles {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1300px;
  text-align: left; }

.article {
  display: inline-block;
  position: relative;
  padding: 10px 25px;
  min-height: 50vh;
  margin: 80px 20px;
  width: 100%;
  transition: all .25s ease-out;
  color: #222; }
  @media (max-width: 767px) {
    .article {
      margin: 60px 0px 60px 0; } }
  .article__media {
    width: 52.5%; }
    @media (max-width: 767px) {
      .article__media {
        width: 100%;
        float: none; } }
    .article__media img {
      width: 100%; }
  .article__info {
    width: 37.5%; }
    @media (max-width: 767px) {
      .article__info {
        width: 100%;
        float: none; } }
  .article:nth-child(odd) .article__media {
    float: left; }
  .article:nth-child(odd) .article__info {
    float: right;
    margin-left: 10%; }
  .article:nth-child(even) .article__info {
    float: left;
    margin-right: 10%; }
  .article:nth-child(even) .article__media {
    float: right; }
  .article__count {
    display: none;
    margin: -20px 0 30px 0;
    font-size: 10px;
    line-height: 30px;
    font-weight: 400;
    color: #aaa; }
  .article__title {
    display: block;
    margin: -14px 0 0px -4px;
    padding: 0;
    font-size: 50px;
    font-weight: 700;
    line-height: 58px;
    letter-spacing: 0;
    transition: all .2s ease-in-out; }
    @media (max-width: 767px) {
      .article__title {
        margin: 10px 0 0px -4px;
        font-size: 40px;
        line-height: 48px; } }
  .article__image {
    width: 100%; }
  .article__cta {
    margin: 35px 0 0 0;
    /*margin: 0 10px 0 0;
		padding: 10px;
		border: 5px solid #222 !important;*/ }
  .article__custom {
    font-size: 10px;
    line-height: 14px;
    font-weight: 700;
    color: #080808;
    font-family: 'Bitter', sans-serif; }
    .article__custom span {
      display: inline-block;
      margin: 0 20px 0 0; }
      .article__custom span small {
        display: none;
        text-transform: uppercase; }
      .article__custom span:empty {
        display: none; }
  .article__date {
    display: inline-block;
    margin: 5px 20px 5px 0;
    padding: 0;
    font-size: 10px;
    line-height: 14px;
    font-weight: 700;
    color: #449595;
    font-family: 'Bitter', sans-serif; }
  .article__text {
    display: block;
    margin: 25px 0 0 0;
    padding: 0;
    font-size: 30px;
    line-height: 40px;
    font-weight: 300;
    color: #222; }
    @media (max-width: 767px) {
      .article__text {
        margin: 30px 0 0 0;
        font-size: 20px;
        line-height: 28px; } }
  .article a {
    color: #080808;
    font-weight: 700;
    text-decoration: none;
    border-bottom: 2px solid #080808;
    transition: all .2s ease-in-out; }

.empty {
  display: inline-block;
  position: relative;
  padding: 10px 25px;
  margin: 40px 20px;
  transition: all .25s ease-out;
  color: #0D0D0D;
  font-size: 50px;
  line-height: 58px;
  font-weight: 300;
  letter-spacing: 0;
  transition: all .2s ease-in-out; }
  @media (max-width: 767px) {
    .empty {
      margin: 10px 0 10px 0;
      font-size: 40px;
      line-height: 48px; } }

.scroll {
  position: fixed;
  z-index: 10;
  bottom: -50px;
  left: 50%;
  transition: all .25s ease-in-out; }
  .scroll--active {
    bottom: 10px; }

.react-images__footer__count {
  font-size: 9px;
  line-height: 12px;
  font-weight: 400;
  color: #f7f7f7;
  font-family: 'Bitter', sans-serif; }

.figure {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: #f2f2f2; }
  .figure.mounted .figure__overlay {
    -webkit-transform: rotate(-6deg) translateX(-100vw);
            transform: rotate(-6deg) translateX(-100vw); }
  .figure.mounted .figure__image {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  .figure__overlay {
    position: absolute;
    z-index: 10;
    left: -18%;
    top: -10%;
    width: 120%;
    height: 120%;
    background: #f7f7f7;
    -webkit-transform: rotate(3deg) translateX(0vw);
            transform: rotate(3deg) translateX(0vw);
    transition: all 1.7s cubic-bezier(0.75, 1, 0, 0.98); }
  .figure__image {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
    transition: all 1.4s ease-in-out; }
  .figure__caption {
    display: none; }

.cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none; }
  @media (max-width: 1024px) {
    .cursor {
      display: none; } }

.cursor--small {
  width: 5px;
  height: 5px;
  left: -2.5px;
  top: -2.5px;
  border-radius: 50%;
  z-index: 11000;
  background: #f7f7f7; }

.cursor--canvas {
  width: 100vw;
  height: 100vh;
  z-index: 12000; }
  @media (max-width: 767px) {
    .cursor--canvas {
      display: none; } }

.lines {
  position: fixed;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  opacity: 1;
  transition: opacity .73s ease-in-out; }
  @media (max-width: 767px) {
    .lines {
      display: none; } }

[data-page="work"] .lines,
[data-page="projects"] .lines,
[data-page="blog"] .lines,
[data-page="experience"] .lines {
  opacity: 0; }

.loader {
  position: fixed;
  z-index: 1200;
  width: 52px;
  height: 52px;
  -webkit-animation: loader-rotate .73s linear infinite;
          animation: loader-rotate .73s linear infinite; }
  @media (max-width: 767px) {
    .loader {
      left: calc( 50vw - 26px) !important;
      top: calc( 50vh - 26px) !important; } }
  .loader[data-loading="false"] span {
    opacity: 0; }
  .loader[data-loading="true"] span {
    opacity: 1; }
  .loader span {
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 12px;
    opacity: 0;
    transition: all .5s ease-in-out; }
    @media (max-width: 767px) {
      .loader span {
        width: 5px;
        height: 5px;
        border-radius: 20px; } }

[data-page="home"] .loader span {
  background: #f7f7f7; }

[data-page="work"] .loader span,
[data-page="projects"] .loader span,
[data-page="blog"] .loader span {
  background: #449595; }

[data-page="experience"] .loader span,
[data-page="contact"] .loader span {
  background: #0D0D0D; }

@-webkit-keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loader-rotate {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

body.detail-open {
  height: 100vh;
  overflow: hidden; }

.detail {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  color: #f7f7f7;
  overflow: auto;
  opacity: 1;
  z-index: 999;
  transition: all 0.73s cubic-bezier(0, 0.78, 0.28, 1);
  -webkit-transform: translateY(0vh);
          transform: translateY(0vh); }
  .detail.mount {
    height: 100vh;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  .detail .wrapper {
    max-width: 1300px;
    margin: 0 auto;
    padding: 100px; }
    @media (max-width: 767px) {
      .detail .wrapper {
        padding: 80px 30px 30px 30px; } }
  .detail__title {
    font-size: 100px;
    font-weight: 700;
    line-height: 80px;
    margin: 0 0 80px 0;
    min-height: 80px; }
    @media (max-width: 767px) {
      .detail__title {
        font-size: 30px;
        line-height: 40px;
        margin: 0 0 40px 0;
        min-height: 40px; } }
    .detail__title span {
      display: inline-block; }
      .detail__title span.space:after {
        opacity: 0;
        content: '.'; }
  .detail__desc {
    display: block;
    overflow: hidden;
    margin: 0 0 80px 0;
    font-size: 30px;
    line-height: 40px;
    font-weight: 300;
    transition: height .1s ease-in; }
    @media (max-width: 767px) {
      .detail__desc {
        font-size: 20px;
        line-height: 28px;
        margin: 0 0 40px 0; } }
  .detail__desc a {
    text-decoration: none;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 700;
    outline: none;
    transition: all .25s ease-out;
    color: #f7f7f7;
    border: 5px solid #f7f7f7; }
    .detail__desc a:hover {
      color: #449595;
      border-radius: 0 15px 0 0;
      border-color: #449595; }
  .detail__media {
    opacity: 0;
    transition: all .5s ease-out; }
    .detail__media.active {
      opacity: 1; }
    .detail__media .figure {
      margin: 0 0 50px 0;
      background: transparent; }
      .detail__media .figure__overlay {
        background: #f7f7f7; }
  .detail img, .detail video {
    width: 100%;
    margin: 0; }

.detail-underlay {
  position: fixed;
  z-index: 998;
  right: 0;
  bottom: 0;
  width: 0vw;
  height: 100vh;
  -webkit-transform: rotate(3deg) scale(2, 2) translateX(20px);
          transform: rotate(3deg) scale(2, 2) translateX(20px);
  background: linear-gradient(-25deg, #4d4d4d, #0D0D0D);
  background-size: 400% 400%;
  transition: all 2.23s cubic-bezier(0.75, 1, 0, 0.98) !important;
  overflow: hidden; }
  .detail-underlay.mount {
    width: 100vw;
    -webkit-transform: rotate(-3deg) scale(2, 2) translateX(20px);
            transform: rotate(-3deg) scale(2, 2) translateX(20px); }

.detail-close {
  position: fixed;
  top: 20px;
  right: -100px;
  z-index: 1001;
  transition: all .2s ease-in .1s;
  color: #f7f7f7;
  border-color: #f7f7f7; }
  .detail-close.mount {
    right: 20px;
    transition: all .2s ease-out; }
    @media (max-width: 767px) {
      .detail-close.mount {
        right: 20px; } }

.blog-articles {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1300px;
  text-align: left; }

.blog-list {
  text-align: left;
  float: right;
  max-width: 30%;
  margin: 80px 20px; }
  @media (max-width: 767px) {
    .blog-list {
      display: none; } }
  .blog-list__item {
    display: block;
    margin: 0 0 0 0;
    padding: 20px 0 10px 20px;
    border-left: 5px solid transparent;
    transition: all .2s ease-in-out; }
    @media (max-width: 767px) {
      .blog-list__item {
        padding: 5px 15px; } }
    .blog-list__item-text {
      font-size: 26px;
      line-height: 32px;
      font-weight: 700;
      color: #0D0D0D;
      transition: all .2s ease-in-out; }
    .blog-list__item-date {
      display: block;
      margin-bottom: 0px;
      font-size: 10px;
      font-weight: 700;
      color: #449595; }
    .blog-list__item.active {
      border-left-color: #449595; }
    .blog-list__item:hover:not(.active) {
      border-left-color: #449595; }

.blog-article {
  float: left;
  display: inline-block;
  position: relative;
  padding: 10px 25px;
  width: 60%;
  min-height: 50vh;
  margin: 80px 20px;
  transition: all .25s ease-out;
  color: #222; }
  @media (max-width: 767px) {
    .blog-article {
      float: none;
      width: auto;
      max-width: 100%;
      margin: 20px 0px 60px 0; } }
  .blog-article__title {
    display: block;
    margin: -14px 0 20px -4px;
    padding: 0;
    font-size: 50px;
    font-weight: 700;
    line-height: 58px;
    letter-spacing: 0;
    transition: all .2s ease-in-out; }
    @media (max-width: 767px) {
      .blog-article__title {
        margin: 10px 0 10px -4px;
        font-size: 40px;
        line-height: 48px; } }
  .blog-article__media figure {
    margin: 30px 0; }
    .blog-article__media figure img {
      width: 100%;
      display: block; }
  .blog-article__date {
    display: block;
    margin: 0 20px 25px 0;
    padding: 0;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    color: #449595;
    font-family: 'Bitter', sans-serif; }
  .blog-article__desc {
    display: inline-block;
    margin: 0 0 25px 0;
    padding: 0;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: #080808;
    font-family: 'Bitter', sans-serif; }
  .blog-article__text {
    display: block;
    margin: 0 0 0 0;
    padding: 0;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: #222; }
    @media (max-width: 767px) {
      .blog-article__text {
        margin: 10px 0 0 0; } }
    .blog-article__text h2 {
      font-size: 35px;
      line-height: 43px; }
    .blog-article__text h3 {
      font-size: 30px;
      line-height: 38px; }
    .blog-article__text h4 {
      font-size: 25px;
      line-height: 32px; }
    .blog-article__text a {
      color: #080808;
      font-weight: 700;
      text-decoration: none;
      border-bottom: 2px solid #080808;
      transition: all .2s ease-in-out; }
    .blog-article__text figure {
      margin: 0;
      width: 100%; }
      .blog-article__text figure img {
        max-width: 100%; }
    .blog-article__text pre {
      font-size: 12px;
      line-height: 20px;
      white-space: pre-wrap;
      word-wrap: break-word; }
    .blog-article__text table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      border: none; }
      .blog-article__text table tbody {
        min-width: 100vw; }
      .blog-article__text table tr:first-of-type td {
        background: #449595;
        color: #f7f7f7; }
      .blog-article__text table tr td {
        padding: 5px 15px;
        font-size: 13px;
        background: #f7f7f7; }
  .blog-article__social {
    margin-top: 50px;
    display: none; }
    .blog-article__social a {
      margin: 0; }
      .blog-article__social a > div {
        border-radius: 0;
        background: transparent;
        transition: all .2s ease-in-out; }
      .blog-article__social a svg path {
        fill: #222; }
      .blog-article__social a:hover > div {
        background: #222; }
      .blog-article__social a:hover svg path {
        fill: #fff; }

.blog-pages {
  text-align: left;
  margin: 20px 20px;
  display: none; }
  @media (max-width: 767px) {
    .blog-pages {
      display: block; } }
  .blog-pages__title {
    display: block;
    margin: -14px 0 20px -4px;
    padding: 0;
    font-size: 40px;
    font-weight: 700;
    line-height: 58px;
    letter-spacing: 0;
    transition: all .2s ease-in-out; }
  .blog-pages__item {
    display: block;
    margin: 8px 0;
    padding: 5px 0 5px 20px;
    transition: all .2s ease-in-out;
    vertical-align: bottom; }
    .blog-pages__item-text {
      font-size: 16px;
      line-height: 22px;
      font-weight: 700;
      color: #0D0D0D;
      transition: all .2s ease-in-out; }
    .blog-pages__item-date {
      margin-right: 5px;
      font-size: 10px;
      font-weight: 700;
      line-height: 22px;
      color: #449595; }
    .blog-pages__item.active {
      padding-left: 15px;
      border-left: 5px solid #449595; }
      .blog-pages__item.active .blog-pages__item-date {
        padding-left: 10px; }
    .blog-pages__item:hover:not(.active) .blog-pages__item-text {
      color: #449595; }

