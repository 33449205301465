.home,
.work,
.projects,
.experience,
.blog,
.contact{
	min-height: 100vh;
	display: flex;
	align-items: flex-start;
	text-align: center;
	
	& > .wrapper{
		width: 100%;
		margin: 130px 0 0 0;
		display: block;
		overflow: hidden;
	}
}


.home{

	align-items: center;
	overflow: hidden;
	background-color: $color_background;

	position: relative;
	width: 100vw;
	height: 100vh;
	
	/*
	background-image: url('/assets/img/bg.jpg');
	background-position: center 25%;
	background-size: cover;
	*/

	transition: all 1s ease-in-out;

	&.transit{
		.home__overlay{
			background: rgba(10, 10, 10, 0.98);
		}
		.home__video{
			opacity: 1;
		}
		.home__content{
			opacity: 0;
		}
		.home__transit{
			transform: scale(0,1);
		}
		.home__prefix{
			opacity: 0;
		}
		.home__suffix{
			opacity: 0;
		}
		.home__subcontent{
			opacity: 0;
		}
	}

	&__overlay{
		position: absolute;
		z-index: 0;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		overflow: hidden;
		background: rgba(0,0,0,.4);
		transition: all .5s ease-in-out;
	}
	&__duration{
		position: absolute;
		z-index: 2;
		left: 0;
		bottom: 0;
		height: 5px;
		width: 0;
		background: rgba(250, 187, 187, 1);
		transition: width .5s ease-out;

		@include breakpoint('small'){
			//background: rgba(255,255,255,1);
			height: 5px;
		}
	}
	&__video{
		//display: none;
		position: absolute;
		z-index: 0;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		opacity: 1;
		overflow: hidden;
		object-fit: cover;
		object-position: 50% 50%;
		transition: opacity .5s ease-in;
		//clip-path: url(#hometransit);
	}
	&__image{
		//display: none;
		position: absolute;
		z-index: 0;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		opacity: 1;
		overflow: hidden;
		object-fit: cover;
		background-size: cover;
		background-position: center center;
		transition: all .7s cubic-bezier(0.6, -0.28, 0.735, 0.045);
		animation: 5s zoom infinite;
	}
	&__transit{
		position: absolute;
		z-index: 0;
		width: 50vw;
		height: 20vh;
		top: 40vh;
		left: 25vw;
		overflow: hidden;
		background: rgba(0,0,0,.8);
		transform-origin: 50% 50%;
		transform-style: preserve-3d;
		transform: scale(1,1);
		transition: all .7s cubic-bezier(0.6, -0.28, 0.735, 0.045);
		//transition: all .7s ease-out;
		box-shadow: 0 0 50px rgba(0,0,0,1);
		//animation: 2s wobble infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
	}

	&__mute{
		position: fixed;
		z-index: 2;
		min-width: 100px;
		bottom: 25px;
		left: 25px;
		color: #fff;
		border-color: #fff;
		//background: $color_background;

		&:hover{
			span{
				&:after{
					border-bottom-color: $color_brand;
				}
			}
		}

		span{
			position: relative;
			width: 100%;
			display: block;
			text-align: center;
			pointer-events: none;
			&:after{
				display: block;
				position: absolute;
				width: 0%;
				content: "";
				border-bottom: 2px solid $color_text;
				left: 50%;
				margin-left: 0px;
				right: 0;
				top: calc( 50% );
				transition: all .5s ease-in-out;
			}
		}

		&.muted{
			span{
				&:after {
					margin-left: -20px;
					width: 40px;
					
				}
			}
		}

	}

	&__mute-status{

		position: fixed;
		z-index: 2;
		min-width: 50px;
		bottom: 55px;
		left: 25px;
		color: #fff;
		border-color: #fff;

		&:not(.muted){
			
			.item--0,
			.item--1,
			.item--2{
				display: block;
				position: absolute;
				content: "";
				//border-top: 5px dotted white;
				width: 125px;
				height: 4px;
				z-index: 2000;
				left: 0;
				padding: 0;
				animation: border-dance 15s infinite linear;

				//background: #fff;
				background: linear-gradient(90deg, white 50%, transparent 50%), linear-gradient(90deg, white 50%, transparent 50%), linear-gradient(0deg, white 50%, transparent 50%), linear-gradient(0deg, white 50%, transparent 50%);
				background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
				background-size: 20px 5px, 20px 5px, 5px 20px, 5px 20px;
				background-position: 0px 0px, 200px 100px, 0px 100px, 200px 0px;
				opacity: 1;

			}

			.item--2{
				left: 30px;
				top: -45px;
				width: 40px;
				display: none;
				//transform: rotate(-90deg) translate(35px,70px);
			}
			.item--1{
				left: 20px;
				top: -35px;
				width: 60px;
				display: none;
				//transform: rotate(-90deg) translate(35px,60px);
			}
			.item--0{
				left: 10px;
				top: -35px;
				width: 80px;
				//transform: rotate(-90deg) translate(35px,50px);
			}
			
			

			.item--disabled{
				//opacity: .15;
				animation: none;
			}
		}
	}

	.wrapper{
		position: relative;
		max-width: 1200px;
		margin: 50px auto;
		text-align: center;

		@include breakpoint('small'){
			overflow: visible;
		}

		.home__content{
			display: inline-flex;
			align-items: flex-start;
			margin-top: 0;
			font-size: 94px;
			line-height: 155px;
			font-weight: 700;
			letter-spacing: -3px;
			transition: all .5s ease-in-out;

			@include breakpoint('large'){
				display: inline-block;
				align-items: initial;
				line-height: 115px;
			}

			@include breakpoint('small'){
				padding: 0 25px;
				font-size: 70px;
				line-height: 80px;
			}
		}

		.home__prefix{
			position: relative;
			font-weight: 700;
			color: $color_text;
			transition: all .15s ease-out;
			//text-transform: capitalize;
		}
		.home__suffix{
			//display: none;
			font-weight: 700;
			color: $color_text;
			transition: all .35s ease-in;
			//text-transform: capitalize;
		}
		.home__letter{
			display: inline-flex;
			align-items: baseline;
			//vertical-align: sub;
			max-width: 0;
			overflow: hidden;
			transform: translateX(-10px) translateY(10px) rotate(30deg);
			transition: all .3s ease-out 2s;
			letter-spacing: -1.5px;

			color: $color_text;

			&--break{
				@include breakpoint('medium'){
					display: block;
					line-height: 0;
				}
			}

			&.active{
				opacity: 1;
				max-width: 200px;
				transform: translateX(0px);
			}
		}

	}	

	.home__text{
		@include breakpoint('small'){
			//display: block;
		}
	}

	.home__subcontent{
		position: absolute;
		display: inline-block;
		top: 0;
		right: 0;
		transition: all 2s ease-out;

		margin: 0 4px 0 0;
		min-width: 300px;
		text-align: right;
		font-size: 25px;
		line-height: 25px;
		font-weight: 700;
		letter-spacing: -1px;
		color: $color_text;

		@include breakpoint('small'){
			top: -25px;
			right: 0px;
			width: 100%;
		}
	}
}

.experience{

	margin: 0 auto;
	background-color: $color_brand;
	transition: all 1s ease-in-out;

	.items{
		max-width: 1300px;
		display: inline-flex;
		flex-direction: row;
		flex-wrap: wrap;
		counter-reset: cntr;
	}

	.item{
		position: relative;
		//max-width: 500px;
		margin: 40px 40px 0 20px;
		text-align: left;
		//display: block;

		@include breakpoint( 'small' ){
			margin: 20px 10px;
		}
		&:before{
			opacity: 0.2;
		}
		&__title{
			color: $color_text;
		}
		&__subtitle{
			color: $color_background;
		}
		&__date{
			color: $color_background;
		}

		&:before{
			position: absolute;
			top: 0%;
			left: 26px;
			counter-increment: cntr +1;
			content: counter(cntr) ".";
			font-size: 10px;
			font-weight: 700;
			color: $color_background;
		}
	}
}

.work{
	
	background-color: $color_text;
	transition: all 1s ease-in-out;
	.item{
		display: block;
	}

	.react-images__navigation button{
		background: $color_brand !important;
	}
}

.projects{
	background-color: $color_text;
	transition: all 1s ease-in-out;

	.react-images__navigation button{
		background: $color_brand !important;
	}
}

.blog{
	background-color: white;

	.article{
		
	
		&__text{
			margin: 10px 0 0 0;
			font-size: 12px;
			line-height: 19px;
		}

	}

	.react-images__navigation button{
		background: $color_brand !important;
	}
}

.contact{

	position: relative;
	align-items: center;
	/*background-image: url('/assets/img/bg/home.jpgXXX');*/
	background-position: bottom center;
	background-size: cover;
	background-color: $color_brand;

	transition: all 1s ease-in-out;
	
	.wrapper{
		position: relative;
		margin: 50px;

		@include breakpoint( 'small' ){
			margin: 50px 20px;
		}

		.contact__content{
			display: inline-flex;
			align-items: flex-end;
			margin-top: 0;
			font-size: 94px;
			line-height: 110px;
			font-weight: 700;
			color: $color_text;
			letter-spacing: -3px;

			@include breakpoint('medium'){
				display: inline-block;
				align-items: initial;
			}

			@include breakpoint('small'){
				font-size: 70px;
				line-height: 80px;
			}
		}

		.contact__prefix{
			color: $color_background;
			font-weight: 700;
			letter-spacing: -7px;
		}

		.contact__letter{
			display: inline-block;
			vertical-align: sub;
			transition: all .25s ease-out;
			opacity: 0;
			max-width: 0;
			overflow: hidden;
			transform: translateX(-30px) translateY(30px) rotate(30deg);

			&--break{
				@include breakpoint('medium'){
					display: block;
					line-height: 0;
				}
			}

			&.active{
				opacity: 1;
				max-width: 200px;
				transform: translateX(0px);
			}
		}

	}

	.contact__links{

		margin: 50px 0 0 0;
		color: $color-text;

		@include breakpoint( 'small' ){
			margin: 25px 0 0 0;
		}

		a{
			display: inline-block;
			margin: 20px 20px;
			font-size: 12px;
			line-height: 30px;
			font-weight: 700;
			//font-family: 'Bitter', sans-serif;
			text-decoration: none;
			
			color: $color_text;
			transition: all .2s ease-in-out;

			&:hover{
				color: #fff;
			}
		}
	}

}

.error{

	height: 100vh;
	display: flex;
	align-items: center;

	.wrapper{

		margin: 0 auto;
		width: 600px;
		text-align: center;

		p{
			margin-top: 0;
			padding: 0 10px;
			font-size: 13px;
			line-height: 21px;
			color: #444;
		}

	}	
}


/*** animatos */

[data-page="home"]{
	background: $color_background;
}
[data-page="work"],
[data-page="projects"]{
	background: $color_text;
}
[data-page="experience"],
[data-page="contact"]{
	background: $color_brand;
}

[data-animation="false"]{
	.work,
	.experience,
	.projects{
		transform: translateX(0);
		opacity: 1;
	}
}

[data-animation="true"]{
	.work,
	.projects,
	.experience{
		transform: translateX(0px);
		opacity: 0;
	}
}
