.cursor {
	position: fixed;
	left: 0;
	top: 0;
	pointer-events: none;

	@include breakpoint( 'medium' ){
		display: none;
	}
  }
  .cursor--small {
	width: 5px;
	height: 5px;
	left: -2.5px;
	top: -2.5px;
	border-radius: 50%;
	z-index: 11000;
	background: $color_text;
  }
  .cursor--canvas {
	width: 100vw;
	height: 100vh;
	z-index: 12000;

	@include breakpoint( 'small' ){
		display: none;
	}
}

.lines{
	position: fixed;
	z-index: 0;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	pointer-events: none;
	opacity: 1;
	transition: opacity .73s ease-in-out;

	//filter: blur(1px);
	//-webkit-filter: blur(1px);

	@include breakpoint( 'small' ){
		display: none;
	}
}

[data-page="work"],
[data-page="projects"],
[data-page="blog"],
[data-page="experience"]{
	.lines{
		opacity: 0;
	}
}