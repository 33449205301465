.items{
	position: relative;
	margin: 0;
	width: 100%;
	text-align: left;
}
.item{
	display: inline-block;
	position: relative;
	margin: 40px 20px;
	padding: 10px 25px;
	overflow: hidden;
	color: $color-text;

	transition: all .93s cubic-bezier(0.6, -0.28, 0.735, 0.045);
	transform-origin: right bottom;
	transform: skewX(25deg) translateX(20px) scale(1.1,.5);
	max-height: 0;
	opacity: 0;

	@include breakpoint( 'small' ){
		display: block;
	}

	&.active{
		transform: skewx(0deg) translatex(0px) scale(1,1);
		max-height: 200px;
		opacity: 1;
	}


	&[data-detail="true"]{
		//cursor: crosshair;

		&:hover{
			.item__title{
				color: $color_brand;
			}
		}
	}

	&[aria-pressed]{
		//text-align: center;
		//padding: 25px 25px;
		//background: #eee;
	}

	.wrapper{
		width:100%;
	}

	&:before{
		color: $color-text;
	}

	&__title{
		display: inline-block;
		margin: 0 0 0 0px;
		padding: 0 0 10px 0;
		font-size: 50px;
		line-height: 58px;
		font-weight: 700;
		letter-spacing: 0;
		//font-family: 'Bitter', sans-serif;
		transition: all .2s ease-in-out;

		@include breakpoint( 'small' ){
			margin: 10px 0 10px 0;
			font-size: 40px;
			line-height: 48px;
		}
	}

	&__image{
		display: none;
		max-width: 500px;
	}

	&__date{
		float: left;
		display: block;
		margin: 0 5px 0 0;
		padding: 0;
		font-size: 9px;
		line-height: 10px;
		font-weight: 700;
		color: $color-brand;
		font-family: 'Bitter', sans-serif;
	}

	&__subtitle{
		float: left;
		display: block;
		margin-top: 0;
		padding: 0;
		font-size: 9px;
		line-height: 10px;
		font-weight: 700;
		color: $color-text;
		font-family: 'Bitter', sans-serif;
	}

	&__description{
		display: block;
		max-width: 80%;
		margin-top: 15px;
		padding: 0;
		font-size: 13px;
		line-height: 20px;
		color: rgba($color-text,.9);

		a{
			color: inherit;
			font-weight: 700;
			text-decoration: underline;
		}
	}

	&__cta{
		margin: 0 0 0 25px;
	}

}


.articles{
	position: relative;
	margin: 0 auto;
	width: 100%;
	max-width: 1300px;
	text-align: left;
}

.article{
	display: inline-block;
	position: relative;
	padding: 10px 25px;
	min-height: 50vh;
	margin: 80px 20px;
	width: 100%;
	//display: flex;
	//align-items: center;
	transition: all .25s ease-out;
	color: #222;

	@include breakpoint( 'small' ){
		margin: 60px 0px 60px 0;
	}

	&__media{
		width: 52.5%;

		@include breakpoint( 'small' ){
			width: 100%;
			float: none;
		}

		img{
			width: 100%;
		}

	}
	&__info{
		width: 37.5%;

		@include breakpoint( 'small' ){
			width: 100%;
			float: none;
		}
	}

	$this: &;

	&:nth-child(odd){
		#{$this}__media{
			float: left;
		}
		#{$this}__info{
			float: right;
			margin-left: 10%;
		}
	}

	&:nth-child(even){
		#{$this}__info{
			float: left;
			margin-right: 10%;
		}
		#{$this}__media{
			float: right;
		}
	}

	&__count{
		display: none;
		margin: -20px 0 30px 0;
		font-size: 10px;
		line-height: 30px;
		font-weight: 400;
		color: #aaa;
		//font-family: 'Bitter', sans-serif;
	}

	&__title{
		display: block;
		margin: -14px 0 0px -4px;
		padding: 0;
		font-size: 50px;
		font-weight: 700;
		line-height: 58px;
		letter-spacing: 0;
		//font-family: 'Bitter', sans-serif;
		transition: all .2s ease-in-out;

		@include breakpoint( 'small' ){
			margin: 10px 0 0px -4px;
			font-size: 40px;
			line-height: 48px;
		}
	}

	&__image{
		width: 100%;
	}

	&__cta{
		margin: 35px 0 0 0;
		/*margin: 0 10px 0 0;
		padding: 10px;
		border: 5px solid #222 !important;*/
	}
	&__custom{
		font-size: 10px;
		line-height: 14px;
		font-weight: 700;
		color: invert( $color-text );
		font-family: 'Bitter', sans-serif;

		span{
			display: inline-block;
			margin: 0 20px 0 0;

			small{
				display: none;
				text-transform: uppercase;
			}
			&:empty{
				display: none;
			}
		}
	}

	&__date{
		display: inline-block;
		margin: 5px 20px 5px 0;
		padding: 0;
		font-size: 10px;
		line-height: 14px;
		font-weight: 700;
		color: $color-brand;
		font-family: 'Bitter', sans-serif;
	}

	&__text{
		display: block;
		margin: 25px 0 0 0;
		padding: 0;
		font-size: 30px;
		line-height: 40px;
		font-weight: 300;
		color: #222;
		//font-family: 'Bitter', sans-serif;

		@include breakpoint( 'small' ){
			margin: 30px 0 0 0;
			font-size: 20px;
			line-height: 28px;
		}
	}

	a{
		color: invert( $color-text );
		font-weight: 700;
		text-decoration: none;
		border-bottom: 2px solid invert( $color-text );
		transition: all .2s ease-in-out;
	}
}



.empty{
	display: inline-block;
	position: relative;
	padding: 10px 25px;
	//min-height: 50vh;
	margin: 40px 20px;
	//display: flex;
	//align-items: center;
	transition: all .25s ease-out;
	color: $color-background;

	font-size: 50px;
	line-height: 58px;
	font-weight: 300;
	letter-spacing: 0;
	//font-family: 'Bitter', sans-serif;
	transition: all .2s ease-in-out;

	@include breakpoint( 'small' ){
		margin: 10px 0 10px 0;
		font-size: 40px;
		line-height: 48px;
	}
}

.scroll{
	position: fixed;
	z-index: 10;
	bottom: -50px;
	left: 50%;

	transition: all .25s ease-in-out;

	&--active{
		bottom: 10px;
	}
}


.react-images{

	&__container{

	}

	&__footer{
		//display: none !important;
		&__count{
			font-size: 9px;
			line-height: 12px;
			font-weight: 400;
			color: $color-text;
			font-family: 'Bitter', sans-serif;
		}
	}

}