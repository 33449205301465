@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700|Bitter:400,700,900');

body{	
	font-family: 'Source Sans Pro', sans-serif;
}

h1,h2,h3,h4,h5,h6{
	font-weight: 100;
}

p,a{
	margin: 0; 
	padding: 0;
}

a{
	text-decoration: none;
	font-weight: 400;
	transition: all .2s ease-in-out;

	&:hover{
		text-decoration: none;
	}
}

button{
	padding: 0;
	line-height: 1.3;
	border:none;
	background: none;
}