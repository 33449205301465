.figure{

	position: relative;
	margin: 0;
	padding: 0;
	overflow: hidden;

	background: darken($color_text,2%);

	// states

	&.mounted{

		.figure__overlay{
			transform: rotate(-6deg) translateX(-100vw);
		}
		.figure__image{
			transform: scale(1,1);
		}

	}

	// elements

	&__overlay{
		position: absolute;
		z-index: 10;
		left: -18%;
		top: -10%;

		width: 120%;
		height: 120%;

		background: $color_text;

		transform: rotate(3deg) translateX(0vw);
		transition: all 1.7s cubic-bezier(.75,1,0,.98);
	}

	&__image{
		transform: scale(1.05,1.05);
		transition: all 1.4s ease-in-out;
	}

	&__caption{
		display: none;
	}

}